import React from 'react'

function Videos2() {
  return (
    <div className="videos">
      <div className="embedHolder">
        <div className="embedText">Bridging To Polygon</div>
        <embed src="https://rumble.com/embed/v18h4u5/?pub=14gts4" />
      </div>
    </div>

  )
}

export default Videos2;
